import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import MenuIcon from "mdi-material-ui/Menu";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

// Images imports
import whiteLogo from "../../assets/logo/effia-logo-white.png";
import Logo from "../../assets/logo/Effia-Logo.png";
import IconNotifActif from "../../assets/icons/notificationActif.svg";
import IconNotif from "../../assets/icons/notification.svg";

// ** Icons imports
import BellOutline from "mdi-material-ui/BellOutline";
import MenuDown from "mdi-material-ui/MenuDown";

// ** React router imports
import { useNavigate } from "react-router-dom";

// ** Third party imports
import { useTranslation } from "react-i18next";

// ** Functions imports
import { GetToken } from "../../utils/getToken";

// ** Redux imports
import { useSelector } from "react-redux";
import {
  deleteNotification,
  getNotifications,
  logoutUser,
  updateNotification,
} from "../../redux/auth/authSlice";
import { useAppDispatch } from "../../redux/hooks";

// ** Components imports
import { LoginDialogBtn } from "../modals/loginModal";
import { SearchCityModal, SearchCityNavbar } from "../styledComponents";
import Notification from "../notification/notification";
import { t } from "i18next";
import { getUserById, updateUserLanguage } from "../../redux/user/userSlice";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  mobileOpen?: any;
  handleDrawerToggle?: any;
}

function stringAvatar(name: string) {
  let initials;
  if (name) {
    initials = name
      .split(" ")
      .map((part) => part[0]) // Get the first letter of each part
      .join(""); // Join the initials to form a single string
  }
  return {
    children: initials,
  };
}

const DrawerMenu = (props: Props) => {
  const { window, mobileOpen, handleDrawerToggle } = props;
  // ** Translation
  const { t } = useTranslation();

  // ** Router
  const navigate = useNavigate();

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const helpMenu = [
    {
      title: t("Réserver votre place de parking"),
      link: "/besoin-daide/reservez-votre-place-de-parking",
    },
    {
      title: t("Souscrivez un abonnement"),
      link: "/besoin-daide/souscrivez-un-abonnement",
    },
    {
      title: "FAQ",
      link: "/besoin-daide",
    },
  ];
  const whoAreWe = [
    {
      title: t("Effia en bref"),
      link: "/qui-sommes-nous/effia-deplacez-vous-malin",
    },
    {
      title: t("Notre expertise sur voirie"),
      link: "/qui-sommes-nous/notre-expertise-sur-voirie",
    },
    {
      title: t("Notre savoir faire parking"),
      link: "/qui-sommes-nous/notre-savoir-faire-parking",
    },
  ];
  return (
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: "block", md: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: "60%",
        },
      }}>
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
        <img
          src={Logo}
          alt=""
          style={{
            margin: "18px 0px 18px 0px",
            width: "5.5rem",
            height: "1.5rem",
          }}
        />

        <List>
          <ListItem disablePadding>
            <ListItemButton disabled>
              <ListItemTextStyled primary={t("BESOIN D'AIDE ?")} />
            </ListItemButton>
          </ListItem>
          {whoAreWe.map((item) => (
            <ListItem key={item.title} disablePadding>
              <ListItemButton onClick={() => navigate(item.link)}>
                <ListItemTextStyled primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
          <Divider variant="middle" />
          <ListItem disablePadding>
            <ListItemButton disabled>
              <ListItemTextStyled primary={t("QUI SOMMES-NOUS")} />
            </ListItemButton>
          </ListItem>
          {helpMenu.map((item) => (
            <ListItem key={item.title} disablePadding>
              <ListItemButton onClick={() => navigate(item.link)}>
                <ListItemTextStyled primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
          <Divider variant="middle" />
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/effia-recrute")}>
              <ListItemTextStyled primary={t("EFFIA recrute")} />
            </ListItemButton>
          </ListItem>
          <Divider variant="middle" />
        </List>
      </Box>
    </Drawer>
  );
};

function HelpMenu() {
  // ** Translation
  const { t } = useTranslation();
  // Router
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigate = (link: string) => {
    setAnchorEl(null);
    navigate(link);
  };
  // **
  const helpMenu = [
    {
      title: t("Réserver votre place de parking"),
      link: "/besoin-daide/reservez-votre-place-de-parking",
    },
    {
      title: t("Souscrivez un abonnement"),
      link: "/besoin-daide/souscrivez-un-abonnement",
    },
    {
      title: "FAQ",
      link: "/besoin-daide",
    },
  ];

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<MenuDown />}
        // onMouseOver={handleClick}
        sx={{
          color: "white",
          cursor: "pointer",
          fontSize: 16,
          "&:hover": { color: "#f39325", backgroundColor: "transparent" },
        }}
        disableRipple>
        {t("BESOIN D'AIDE ?")}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {helpMenu.map((menu: any, index: number) => (
          <MenuItemStyled
            key={index}
            color="secondary"
            onClick={() => handleNavigate(menu.link)}
            disableRipple>
            {menu.title}
          </MenuItemStyled>
        ))}
      </StyledMenu>
    </div>
  );
}

function AboutMenu() {
  // ** Translation
  const { t } = useTranslation();

  // Router
  const navigate = useNavigate();

  // ** States
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigate = (link: string) => {
    setAnchorEl(null);
    navigate(link);
  };
  // **

  const whoAreWe = [
    {
      title: t("Effia en bref"),
      link: "/qui-sommes-nous/effia-deplacez-vous-malin",
    },
    {
      title: t("Notre expertise sur voirie"),
      link: "/qui-sommes-nous/notre-expertise-sur-voirie",
    },
    {
      title: t("Notre savoir faire parking"),
      link: "/qui-sommes-nous/notre-savoir-faire-parking",
    },
  ];
  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<MenuDown />}
        // onMouseOver={handleClick}
        sx={{
          color: "white",
          fontSize: 16,
          cursor: "pointer",
          "&:hover": { color: "#f39325", backgroundColor: "transparent" },
        }}
        disableRipple>
        {t("QUI SOMMES-NOUS")}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {whoAreWe.map((menu: any, index: number) => (
          <MenuItemStyled
            key={index}
            color="secondary"
            onClick={() => handleNavigate(menu.link)}
            disableRipple>
            {menu.title}
          </MenuItemStyled>
        ))}
      </StyledMenu>
    </div>
  );
}

const UserMenu: React.FC<any> = ({ icon }) => {
  // ** Translation
  const { t } = useTranslation();

  // ** Hooks
  const userReducer = useSelector((state: any) => state.auth.user);
  const userr = useSelector((state: any) => state.user.user);

  const notificationsReducer = useSelector(
    (state: any) => state.auth.notifications
  );
  const dispatch = useAppDispatch();

  // Router
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [avatarImage, setAvatarImage] = React.useState<any>(null);
  const [positionMenuNotif, setPositionMenuNotif] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(null);
  // notifications state
  const [notifications, setNotifications] = React.useState<any>({});
  const [existNotifNotRead, setExistNotifNotRead] =
    React.useState<boolean>(false);
  const [userName, setUserName] = React.useState<any>();
  const [companyName, setCompanyName] = React.useState<any>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // **

  const userMenu = [
    t("Mes informations"),
    t("Mes réservations"),
    t("Mes abonnements"),
    t("Service client"),
    t("Déconnexion"),
  ];

  // const keyAccountMenu = [
  //   t("Mes informations"),
  //   t("Mes réservations"),
  //   t("Service client"),
  //   t("Déconnexion"),
  // ];

  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    setPositionMenuNotif(event.currentTarget);
  };

  // handle close notification icon
  const handleMenuClose = () => {
    setPositionMenuNotif(null);
  };

  // handle delete notification
  const handleDeleteNotification = async (notificationID: any) => {
    setLoading(true);
    await dispatch(deleteNotification(notificationID));
    setLoading(false);
  };

  // handle delete all notification
  const handleDeleteAllNotification = async () => {
    setLoading(true);
    const noId = "";
    await dispatch(deleteNotification(noId));
    setLoading(false);
  };

  // handle mark all as read
  const handleToggleReadAll = async () => {
    setLoading(true);
    const noId = "";
    await dispatch(updateNotification(noId));
    setLoading(false);
  };

  // handle change "isRead" value
  const handleToggleRead = async (notificationID: any) => {
    setLoading(true);
    await dispatch(updateNotification(notificationID));
    setLoading(false);
  };

  React.useEffect(() => {
    const avatar = process.env.REACT_APP_STORAGE_URL + userReducer?.avatar;
    setAvatarImage(avatar);
  }, [userReducer?.avatar]);

  React.useEffect(() => {
    const avatar =
      process.env.REACT_APP_STORAGE_URL + userr?.avatar + `?${Date.now()}`;
    setAvatarImage(avatar);
  }, [userr?.avatar]);

  React.useEffect(() => {
    if (userr?.fullName) {
      setUserName(userr.fullName);
    }
  }, [userr?.fullName]);

  React.useEffect(() => {
    if (userReducer?.fullName) {
      setUserName(userReducer.fullName);
    }
  }, [userReducer?.fullName]);

  React.useEffect(() => {
    if (userReducer?.companyName) {
      setCompanyName(userReducer.companyName);
    }
  }, [userReducer?.companyName]); 

  React.useEffect(() => {
    if (userr?.companyName) {
      setCompanyName(userr.companyName);
    }
  }, [userr?.companyName]);

  // get notifications
  const getNotif = async () => {
    setLoading(true);
    await dispatch(getNotifications());
    setLoading(false);
  };
  // get notifications for the first run of component
  React.useEffect(() => {
    getNotif();
  }, []);

  React.useEffect(() => {
    if (notificationsReducer && typeof notificationsReducer === "object") {
      setNotifications(notificationsReducer);
      const notificationsArray = Object.values(notificationsReducer);

      // check if there is a notification not read
      const exist = notificationsArray.some(
        (notification: any) => !notification.isRead
      );
      if (exist) {
        setExistNotifNotRead(true);
      } else {
        setExistNotifNotRead(false);
      }
    }
  }, [notificationsReducer]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {icon ? (
        <IconButton
          onClick={handleClick}
          color="inherit"
          aria-label="open drawer"
          edge="start">
          <Avatar
            style={{
              width: 28,
              height: 28,
            }}
            {...stringAvatar(userName)}
            src={avatarImage}
          />
          {/* <AccountOutline style={{ fontSize: 28 }} /> */}
        </IconButton>
      ) : (
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="text"
          disableElevation
          onClick={handleClick}
          endIcon={<MenuDown />}
          // onMouseOver={handleClick}
          sx={{
            borderRadius: 0,
            color: "white",
            cursor: "pointer",
            borderLeft: "1px solid #8a8a8a6b",
            fontSize: 16,
            "&:hover": { color: "#f39325", backgroundColor: "transparent" },
            pl: 2.5,
          }}
          disableRipple>
          {userReducer?.userType === "particular" ||
          userReducer?.userType === "KeyAccount"
            ? userName
            : companyName}
        </Button>
      )}

      <Box
        sx={{
          marginLeft: "auto",
          borderRight: "1px solid #8a8a8a6b",
          pr: 1.5,
        }}>
        <IconButton
          aria-label={"show notifications"}
          color="inherit"
          onClick={handleClickNotification}
          aria-controls="menuNotif">
          {existNotifNotRead ? (
            <img src={IconNotifActif} alt="Active Notification" />
          ) : (
            <img src={IconNotif} alt="Inactive Notification" />
          )}
        </IconButton>

        <Menu
          anchorEl={positionMenuNotif}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          id="menuNotif"
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(positionMenuNotif)}
          onClose={handleMenuClose}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              borderBottom: "2px solid #DCDCDC",
              p: 0.5,
            }}>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "500",
                letterSpacing: "1px",
                color: "#1b374c",
              }}>
              {t("profile.editProfile.Notifications")}
            </Typography>
            <Badge
              badgeContent={
                notifications && notifications?.length <= 50
                  ? notifications?.length
                  : notifications.length > 50
                  ? "+50"
                  : 0
              }
              color="secondary">
              <BellOutline color="secondary" />
            </Badge>
          </Box>

          {/* LOADING */}
          {loading && <LinearProgress sx={{ width: "100%" }} />}

          <Box
            sx={{
              height: "290px",
              width: "350px",
              overflowY: "auto",
              overflowX: "hidden",
              "&::-webkit-scrollbar": {
                width: "10px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}>
            {/* MAPPING LIST NOTIFICATIONS */}
            {notifications && notifications.length > 0 ? (
              notifications
                ?.slice(0, 50)
                .sort(
                  (a: { createdAt: Date }, b: { createdAt: Date }) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
                )
                .map((notif: any) => (
                  <Notification
                    key={notif.id}
                    onDelete={handleDeleteNotification}
                    onToggleRead={handleToggleRead}
                    createdAt={notifications?.createdAt}
                    {...notif}
                  />
                ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}>
                <Typography>{t("Aucune notification à afficher!")}</Typography>
              </Box>
            )}
          </Box>
          {notifications && notifications.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                borderTop: "2px solid #DCDCDC",
                pt: 1,
                px: 1,
              }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#1b374c",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                component="span"
                onClick={handleToggleReadAll}>
                {t("Marquer tout comme lu")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#1b374c",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                component="span"
                onClick={handleDeleteAllNotification}>
                {t("Supprimer tout")}
              </Typography>
            </Box>
          ) : null}
        </Menu>
      </Box>

      <StyledMenu
        // sx={{ "& .MuiPaper-root": { marginTop: 2 } }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {userMenu.map((title: string, index: number) => (
          <MenuItemStyled
            key={index}
            color="secondary"
            onClick={() => {
              if (index === userMenu.length - 1) {
                //handleLogout();
                dispatch(logoutUser());
              } else {
                handleClose();
                navigate(`/profile`, {
                  state: { value: index }, // Pass the state as an object
                });
              }
            }}
            disableRipple
            style={
              index === userMenu.length - 1
                ? { borderTop: "1px solid #8A8A8A6b", color: "#8A8A8A" }
                : {}
            }>
            {title}
          </MenuItemStyled>
        ))}
      </StyledMenu>
    </Box>
  );
};

function LngMenu() {
  // ** Translation
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const userReducer = useSelector((state: any) => state.auth.user);
  const userr = useSelector((state: any) => state.user.user);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [loading, setLoading] = React.useState<boolean>(false);

  // **
  const options = React.useMemo(
    () => [
      {
        flag: "https://flagcdn.com/fr.svg",
        lang: "Français",
        countryCode: "fr",
      },
      {
        flag: "https://flagcdn.com/gb.svg",
        lang: "Anglais",
        countryCode: "en",
      },
      {
        flag: "https://flagcdn.com/nl.svg",
        lang: "Néerlandais",
        countryCode: "nl",
      },
    ],
    []
  );

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);

    const selectedLanguage = options[index].countryCode;
    changeLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  };

  const changeLanguage = async (lng: string | undefined) => {
    if (!lng) return;
    setLoading(true);
    const language = lng.toUpperCase();
    try {
      if (userReducer || userr) {
        await dispatch(updateUserLanguage({language}));
        i18n.changeLanguage(lng);
      } else {
        i18n.changeLanguage(lng);
      }
    } catch {
      console.error("Failed to update language");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    let defaultLanguage = "fr";

    if (userr?.language) {
      defaultLanguage = userr?.language?.toLowerCase();
    } else if (savedLanguage) {
      defaultLanguage = savedLanguage;
    }

    i18n.changeLanguage(defaultLanguage);

    const selectedLanguageIndex = options.findIndex(
      (option) => option.countryCode === defaultLanguage
    );

    if (selectedLanguageIndex !== -1) {
      setSelectedIndex(selectedLanguageIndex);
    } else {
      setSelectedIndex(0);
    }
  }, [userr?.language]);

  React.useEffect(() => {
    const getuser = async () => {
      await dispatch(getUserById(userReducer?.id));
    };

    getuser();
  }, [userReducer?.id]);

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label={`Change language to ${options[selectedIndex]?.lang}`}
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<MenuDown />}
        // onMouseOver={handleClick}
        sx={{
          color: "white",
          fontSize: 16,
          cursor: "pointer",
          "&:hover": { color: "#f39325", backgroundColor: "transparent" },
          mr: { sm: 0, md: 5 },
        }}
        disableRipple
        disabled={loading}
      >
        {options[selectedIndex].countryCode}
        {/* {loading ? "Loading..." : options[selectedIndex]?.countryCode || ""} */}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItemStyled
            key={index}
            selected={index === selectedIndex}
            onClick={(event: any) => {
              handleMenuItemClick(event, index);
            }}
          >
            <img
              src={option.flag}
              alt={`Flag of ${option.lang}`}
              width={20}
              height={20}
              style={{ marginRight: 10 }}
            />
            {t(option.lang)}
          </MenuItemStyled>
        ))}
      </StyledMenu>
    </>
  );
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 0,
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  fontWeight: 300,
  textTransform: "capitalize",
  paddingBottom: 15,
  "&:hover": {
    color: "#F39325",
    fontWeight: 400,
    backgroundColor: "transparent",
  },
  ".css-yl00x1-MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
    backgroundColor: "red",
  },
}));
const ListItemTextStyled = styled(ListItemText)(({ theme }) => ({
  color: "#484848",
  ".css-1mehjhw-MuiTypography-root": { fontSize: ".875rem" },
}));

export default function NavbarEffia() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // ** Translation
  const { t } = useTranslation();
  // Routes
  const navigate = useNavigate();

  // ** Hooks
  const userReducer = useSelector((state: any) => state.auth.user);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navigateHome = () => {
    const currentPath = window.location.pathname;
    if (currentPath === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate("/");
    }
  };

  // ** Authorization
  const isAuth = GetToken();

  // ** Remove searchSite from navbar
  const isSearchBar =
    window.location.pathname === "/" ||
    window.location.pathname.includes("reset-password") ||
    userReducer?.userType === "KeyAccount" ||
    window.location.pathname.includes("/password-update")
      ? false
      : true;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        color="secondary"
        sx={{
          borderBottom: "5px solid",
          borderColor: "#F39325",
          minHeight: 90,
          verticalAlign: "middle",
          justifyContent: "center",
        }}
        elevation={0}>
        <Toolbar
          sx={{
            px: ".9375rem",
            maxWidth: "84rem",
            mx: "auto",
            width: { md: "100%", xs: "90%" },
          }}>
          <Box
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              justifyContent: "space-between",
              width: "100%",
            }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={navigateHome}>
              <img
                src={whiteLogo}
                alt=""
                style={{ width: "5.5rem", height: "auto" }}
              />
            </IconButton>

            <Box display="flex">
              {isSearchBar && <SearchCityModal />}
              {isAuth ? <UserMenu icon /> : <LoginDialogBtn icon />}
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              cursor: "pointer",
            }}
            onClick={navigateHome}>
            <img src={whiteLogo} width="150px" alt="" />
          </Box>
          {isSearchBar && (
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                flexGrow: 2,
                justifyContent: "end",
                pl: "3%",
              }}>
              {userReducer && userReducer.userType === "KeyAccount" ? null : (
                <SearchCityNavbar />
              )}
            </Box>
          )}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: { xs: 0, sm: 6, md: 6 },
              flexGrow: 1,
              justifyContent: "flex-end",
            }}>
            <Box sx={{ display: "flex" }}>
              <AboutMenu />
              <HelpMenu />
              <Button
                sx={{
                  color: "#fff",
                  fontSize: 16,
                  "&:hover": {
                    color: "#f39325",
                    backgroundColor: "transparent",
                  },
                  mr: -5,
                }}
                disableRipple
                onClick={() => navigate("/effia-recrute")}>
                {t("EFFIA recrute")}
              </Button>
            </Box>
            <Box sx={{ display: "flex" }}>
              {isAuth ? <UserMenu /> : <LoginDialogBtn />}
            </Box>
          </Box>
          <LngMenu />
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <DrawerMenu
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Box>
    </Box>
  );
}
